
import DefaultController from "../defaultController";
export default class Home extends DefaultController {
    async init() {
        await super.init();
    }

    protected getEntityData(elem: any) {
        return null
    }
    bindListeners() {
    }
}